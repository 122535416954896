<template>
    <div class="mine page">
        <div class="page-bg"></div>
        <div class="wrapper">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <div class="header">
                    <van-nav-bar class="nav-bar">
                        <template #right>
                            <!-- <van-icon name="setting-o" @click="showSetting()" color="#fff" /> -->
                            <van-icon name="chat-o" @click="toService()" color="#fff" style="margin: 0 10px 0 10px;" />
                        </template>
                    </van-nav-bar>
                    <div class="user-wrapper" @click="doLogin()">
                        <!-- <van-image round class="user_img" :src="this.userInfo.header_img">
               <template v-slot:loading>
                 <van-loading type="spinner"/>
               </template>
             </van-image> -->
                        <img src="@/assets/avatar.png" style="width: 65px;">
                        <div class="login-content">
                            <p class="login-btn">{{ this.userInfo.username }}
                                <!-- <span style="font-size: 14px; margin-left: 10px;">
							ID:{{ this.userInfo.id }}
							</span> -->
                            </p>
                            <div style="display: flex; align-items: center; color: #fff;">
                                <img src="@/assets/vip.png" alt=""
                                    style="display: block; height: 25px; margin-right: 10px;">
                                <span>{{ $t('CreditScore') }}: {{ userInfo.xinyu || 0 }}</span>
                            </div>
                            <!-- <span class="levelBox">2</span> -->
                            <!-- <p class="login-label">{{ this.userInfo.ip }}</p> -->
                            <!-- <div style="display: flex; align-items: center;"><van-progress style="width: 35%;"
                                    :percentage="50" :show-pivot="false" color="#e6c3a1" stroke-width="4px" /><span
                                    class="needFen" style="color: #fff;">{{ $t('requiredPromotion') }}<i
                                        style="font-weight: bold; margin: 0 2px;">4000</i>{{ $t('integral')
                                        }}</span>
							</div> -->
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="finance">
                        <!--   <div class="finance-item" @click="doPay()">
                            <van-icon class="icon" style="" :name="require('@/assets/mnav1.png')" />
                            <span class="text">{{ $t('rechargePoints') }}</span>
                        </div> -->
                        <div class="line"></div>
                        <div class="finance-item" @click="doWithdrawal()">
                            <van-icon class="icon" :name="require('@/assets/mnav2.png')" />
                            <span class="text">{{ $t('redeemPoints') }}</span>
                        </div>
                    </div>
                    <div v-if="this.userInfo.money" class="wallet">
                        <div class="part-1 van-hairline--bottom">
                            <div class="flex-1 font-28 font-primary-color">{{ $t('myActivityPoints') }}</div>
                            <!-- <span class="font-28 font-gray">Chi tiết</span> -->
                            <!-- <van-icon class="font-gray" style="font-size: 28px" name="arrow" /> -->
                        </div>
                        <div class="part-2">
                            <div class="balance van-ellipsis">{{ this.userInfo.money }}</div>
                            <!-- <span class="font-28 font-gray">积分(个)</span> -->
                            <span class="font-28 font-gray" style="margin-bottom: 40px;">{{ $t('integral') }}</span>
                            <div style="margin-bottom: 40px;" class="refresh-btn" @click="refresh()">
                                <van-icon name="replay" />
                            </div>
                        </div>
                    </div>
                    <div :style="{ marginTop: menu_top + 'px' }" class="menu">
                        <div class="menu-item" @click="toInfomation()">
                            <van-image class="menu-item-icon" src="img/mine/nav1.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('basicInformation') }}</span>
                        </div>
                        <!--  <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
                            <van-image class="menu-item-icon" src="img/mine/1.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('personalReports') }}</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
                            <van-image class="menu-item-icon" src="img/mine/nav2.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">积分明细</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
                            <van-image class="menu-item-icon" src="img/mine/nav3.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">兑换记录</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
                            <van-image class="menu-item-icon" src="img/mine/nav4.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">数据记录</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
                            <van-image class="menu-item-icon" src="img/mine/nav5.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">信息公告</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
                            <van-image class="menu-item-icon" src="img/mine/nav6.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">我的电影</span>
                        </div> -->
                        <div class="menu-item" @click="toLoginPassword()">
                            <van-image class="menu-item-icon" src="img/mine/nav7.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('loginPassword') }}</span>
                        </div>
                        <!-- <div class="menu-item" @click="toPayPassword()">
                            <van-image class="menu-item-icon" src="img/mine/nav8.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('paymentPassword') }}</span>
                        </div> -->

                        <!-- <div class="menu-item" @click="exit()">
                                <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                                <template v-slot:loading>
                                    <van-loading type="spinner"/>
                                </template>
                                </van-image>
                                <span class="menu-item-label" >账户明细</span>
                            </div> -->
                        <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
                            <van-image class="menu-item-icon" src="img/mine/baobiao.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('taskLog') }}</span>
                        </div>
                        <!--    <div class="menu-item" @click="$router.push({ path: '/Infomation' });">
                            <van-image class="menu-item-icon" src="img/mine/3.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">Trung tâm cá nhân</span>
                        </div> -->
                        <div class="menu-item" @click="toNotice()">
                            <van-image class="menu-item-icon" src="img/mine/4.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('noticeAnnouncement') }}</span>
                        </div>
                        <div class="menu-item" @click="toService()">
                            <van-image class="menu-item-icon" src="img/mine/5.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('onlineService') }}</span>
                        </div>
                        <!-- <div class="menu-item" @click="toInfomation()">
                            <van-image class="menu-item-icon" src="img/mine/6.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">信息设置</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="toLoginPassword()">
                            <van-image class="menu-item-icon" src="img/mine/7.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">登录密码</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="toPayPassword()">
                            <van-image class="menu-item-icon" src="img/mine/8.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">提取密码</span>
                        </div> -->
                        <!-- <div class="menu-item" @click="switchLanguage()">
                            <van-image class="menu-item-icon" src="img/mine/nav9.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{ $t('switchLanguage') }}</span>
                        </div> -->
                    </div>
                    <van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{ $t('logOut')
                        }}</van-button>
                </div>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInfo: {
            },
            menu_top: 40,
            isLoading: false,
        };
    },
    methods: {
        // toPayPassword() {
        //     if (this.userInfo.paypassword !== this.$t('notSet')) {
        //         // this.$toast(this.$t('contactService'));
        //         this.$router.push({ path: '/SetPayPassword' });
        //     } else {
        //         this.$router.push({ path: '/SetPayPassword' });
        //     }
        // },
        refresh() {
            this.isLoading = true;
            setTimeout(() => {
                this.isLoading = false;
                if (localStorage.getItem('token')) {
                    this.$toast(this.$t('refreshSuccessful'));
                } else {
                    this.$router.push({ path: '/Login' })
                }
            }, 50);
        },
        exit() {
            this.$toast(this.$t('completeTask'));
        },
        showSetting() {
            if (localStorage.getItem('token')) {
                this.$router.push({ path: '/Setting' })
            } else {
                this.$router.push({ path: '/Login' })
            }
        },
        toNotice() {
            if (localStorage.getItem('token')) {
                this.$router.push({ path: '/Notice' })
            } else {
                this.$router.push({ path: '/Login' })
            }
        },
        onRefresh() {
            setTimeout(() => {
                this.isLoading = false;
                if (localStorage.getItem('token')) {
                    this.getUserInfo();
                    this.$toast(this.$t('refreshSuccessful'));
                } else {
                    this.$router.push({ path: '/Login' })
                }
            }, 500);
        },
        doLogin() {
            if (localStorage.getItem('token')) {
                this.$router.push({ path: '/Infomation' });
            } else {
                this.$router.push({ path: '/Login' })
            }
        },
        doPay() {
            // this.$router.push({ path: 'Recharge' });
            if (this.$store.getters.getBaseInfo.iskefu == 1) {
                // this.$router.push("ServiceOnline");
                location.href = this.$store.getters.getBaseInfo.kefu;
            } else {
                this.$toast(this.$t('invalidFunction'));
            }
            return false;
        },
        doWithdrawal() {
            this.$http({
                method: 'get',
                url: 'user_get_bank'
            }).then(res => {
                if (res.data.is_bank) {
                    this.$router.push("withdraw");
                } else {
                    this.$router.push("Setbank");
                    this.$toast(this.$t('setUpBankCard'));
                }
            })
        },
        toService() {
            return window.location.href = this.$store.getters.getBaseInfo.kefu;
        },
        getUserInfo() {
            this.$http({
                method: 'get',
                url: 'user_info'
            }).then(res => {
                if (res.code === 200) {
                    console.log('user_info', res);
                    this.userInfo = res.data;
                    this.menu_top = 15;
                    if (this.userInfo.status !== 1) {
                        this.$toast(this.$t('accountOffline'));
                        localStorage.clear()
                        this.$router.push({ path: '/Login' })
                    }
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
        loginout() {
            localStorage.clear()
            this.$router.push({ path: '/Login' });
        },
        toInfomation() {
            this.$router.push({ path: '/Infomation' });
        },
        toLoginPassword() {
            this.$router.push({ path: '/SetLoginPassword' });
        },
        switchLanguage() {
            this.$router.push({ path: '/SwitchLanguage' });
        },
    },
    created() {
        if (localStorage.getItem('token')) {
            this.getUserInfo();
        } else {
            this.$router.push({ path: '/Login' });
        }
    }
};
</script>

<style lang="less" scoped>
.page {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f5;
}

.mine {
    position: relative;
    bottom: 10px;
    background: #f2f2f5;
    background: url('~@/assets/usertop.png') no-repeat top -120px center;
    background-size: contain;
    padding-bottom: 50px;
}

.mine .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

}

.nav-bar {
    /* background: linear-gradient(90deg, #7e5678, #e6c3a1); */
    background: content-box;
}

.mine .header {
    /* background: linear-gradient(90deg, #7e5678, #e6c3a1); */
    padding-bottom: 100px;

}

::v-deep .van-nav-bar__content {
    height: 100px;
}

::v-deep .van-hairline--bottom::after {
    border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
    font-size: 45px;
}

.mine .header .user-wrapper {
    display: flex;
    align-items: center;
    margin: 0px 40px 0px 40px;
}

.mine .user_img {
    height: 130px;
    width: 130px;
}

::v-deep .van-loading__spinner {
    height: 50px;
    width: 50px;
}

::v-deep .van-image__error-icon {
    font-size: 70px;
}

.mine .header .user-wrapper .login-content {
    flex: 1;
    margin-left: 30px;
    position: relative;

    .levelBox {
        position: absolute;
        top: 90px;
        left: 125px;
        color: #fff;
        font-weight: bold;
    }
}



.mine .header .user-wrapper .login-content .login-btn {
    display: inline-block;
    font-size: 40px;
    line-height: 0px;
    color: #fff;
}

.mine .header .user-wrapper .login-content .login-label {
    margin-top: -13px;
    font-size: 28px;
    color: hsla(0, 0%, 100%, 0.6);
}

.mine .page-bg {
    height: 500px;
    /* background: linear-gradient(90deg, #7e5678, #e6c3a1); */
}

.mine .content {
    position: relative;
    padding: 10px 30px 30px;
    min-height: 500px;
    background-color: #f2f2f5;

    padding-bottom: 180px;

}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
    color: #ffffff;
    font-size: 35px;
}

.mine .wrapper .content .finance {
    position: absolute;
    display: flex;
    align-items: center;
    top: -55px;
    left: 30px;
    right: 30px;
    height: 120px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
    width: 3px;
    height: 40px;
    background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
    margin-left: 10px;
    font-size: 28px;
    /* color: #000; */
    /* font-weight: 500; */
    color: #432989;
    font-weight: 600;
}

.mine .wrapper .content .finance .finance-item .icon {
    font-size: 50px;
}

.mine .wrapper .content .menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 1.5px 1px 0 #e4e4e7;
    padding-bottom: 20px;
}

.mine .wrapper .content .menu .menu-item {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: flex-start; */
    width: 33.33%;
    /* height: 130px; */
    margin-bottom: 10px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
    font-size: 26px;
    /* color: #868686; */
    font-weight: 500;
    text-align: center;
    display: block;
    color: #432788;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
    margin: 25px;
    width: 60px;
    height: 60px;
    -o-object-fit: contain;
    object-fit: contain;
    display: block;
    margin: 25px auto;
}

.mine .wrapper .content .wallet {
    margin-top: 80px;
    padding: 0 30px;
    /* background-color: #fff; */
    border-radius: 15px;
    /* box-shadow: 0 1.5px 1px 0 #e4e4e7; */
    background: #a06098;
    color: #fff;
}

.mine .wrapper .content .wallet .part-1 {
    display: flex;
    align-items: center;
    height: 80px;
    color: #fff;
}

.mine .wrapper .content .wallet .font-primary-color {
    /* color: #000; */
    color: #fff;
}

.font-gray {
    /* color: #868686; */
    color: #fff;
}

.mine .wrapper .content .wallet .part-2 {
    display: flex;
    align-items: center;
    height: 80px;
}

.mine .wrapper .content .wallet .part-2 .balance {
    flex: 1;
    font-size: 60px;
    /* color: #7e5678; */
    font-weight: 700;
    color: #fff;
}

// .mine .wrapper .content .wallet .van-hairline--bottom::after {
//     border-bottom-width: 3px;
// }

.mine .wrapper .content .wallet .part-2 .refresh-btn {
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 30px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #e6c3a1;
}

.sign-out {
    margin: 80px 0 0 0;
    height: 90px;
    width: 100%;
    line-height: 90px;
    border-radius: 14px;
    color: #fff;
    font-size: 32px;
    font-weight: bolder;
    border: none;
    /* background: linear-gradient(270deg, #e6c3a1, #7e5678); */
    background: linear-gradient(90deg, #6529c9, #cc2996);
}

.needFen {
    font-size: 24px;
    margin-left: 5px;
}
</style>
